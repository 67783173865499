import cn from 'classnames'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { HtmlContentViewer } from 'src/components/global/HtmlContentViewer/HtmlContentViewer'
import { IContentItemWriterFragment, IContentWriterFragment } from 'src/graphql/generated/hooks'

import { ContentWriter } from '../ContentWriter/ContentWriter'

export interface TrendingTopicCardProps {
  title: string
  url: string
  snippet?: string | null
  writer: IContentWriterFragment | IContentItemWriterFragment | string | null
  className?: string | null
}

export const TrendingTopicCard: React.FC<PropsWithChildren<TrendingTopicCardProps>> = ({
  className,
  title,
  snippet,
  url,
  writer,
}) => {
  return (
    <Link href={url} passHref className={cn('h-[16rem] w-full bg-white rounded-md px-8 py-6', className)}>
      <div className="flex flex-col h-[10rem] mb-[1.3rem]">
        <p className="text-textdark font-bold text-[1.4rem] mt-1 h-[3.1rem] overflow-hidden leading-6">{title}</p>
        <div className="h-[4.8rem] mt-[.7rem] overflow-hidden">
          <HtmlContentViewer
            html={snippet || ''}
            contentClassName="header-des-html text-[#707070] text-[1rem] font-primary"
          />
        </div>
      </div>
      {writer && <ContentWriter writer={writer} />}
    </Link>
  )
}
