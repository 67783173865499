import cn from 'classnames'
import React from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'

export interface PremiumPanelProps {
  icon?: React.ReactNode
  title: string
  description: string
  url: string
  className?: string
}

/**
 * Primary UI component for user interaction
 */
export const PremiumContentCategory: React.FC<PremiumPanelProps> = ({ icon, title, description, className, url }) => {
  return (
    <CompatibleLink
      href={url}
      passHref
      className={cn(
        className,
        'flex flex-row min-h-[180px] h-[180px] md:h-[240px] pt-[25px] px-[20px] xl:h-[240px] 2xl:h-[180px] bg-white rounded-[10px]',
      )}
    >
      <div className="w-[40px] h-[40px] mr-4 flex justify-center items-center relative">
        <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[20px] opacity-20 bg-navybright" />
        {icon}
      </div>
      <div className="flex flex-col items-start w-5/6">
        <p className="text-black font-secondary text-[24px] font-bold">{title}</p>
        <div
          style={{ lineHeight: '18px', overflow: 'hidden', maxHeight: 70 }}
          className="font-primary text-[16px] text-shade mb-[15px] mt-1 h-2/3"
        >
          {description}
        </div>
      </div>
    </CompatibleLink>
  )
}
