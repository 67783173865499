export { default } from 'src/containers/Home'
import { GetStaticProps } from 'next'
import { THEME } from 'src/config/config'
import { HomePageProps } from 'src/containers/Home/Home'
import { apolloSdk } from 'src/graphql/apolloSdk'
import { IPopularTopic, ITopicItemFragment } from 'src/graphql/generated/hooks'

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let props: HomePageProps
  if (THEME === 'legaltech') {
    const [
      { premiumAudiences },
      { topics },
      { testimonialsPlural },
      { popularTopics },
      { topics: premiumTopics },
      { subTopics: premiumSubTopics },
    ] = await Promise.all([
      apolloSdk.premiumAudiences({
        filters: { shouldShowOnHomepage: { eq: true } },
        sort: ['name:desc'],
      }),
      apolloSdk.getTopicsWithoutSubs(),
      apolloSdk.globalTestimonials(),
      apolloSdk.popularTopics({ vendorLimit: 6 }),
      apolloSdk.premiumTopics(),
      apolloSdk.premiumSubTopics(),
    ])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const premiumCategories: any[] = (premiumTopics?.data || []).concat((premiumSubTopics?.data || []) as any)
    props = {
      theme: 'legaltech',
      audiences: premiumAudiences?.data || [],
      topics: (topics?.data || []) as ITopicItemFragment[],
      testimonials: testimonialsPlural?.data || [],
      popularTopics: popularTopics as IPopularTopic[],
      premiumCategories,
    }
  } else {
    const [{ topics }, { iltaSponsorships }] = await Promise.all([
      apolloSdk.getTopicsWithoutSubs(),
      apolloSdk.getIltaSponsorships(),
    ])
    props = {
      theme: 'ilta',
      topics: (topics?.data || []) as ITopicItemFragment[],
      iltaSponsorships: iltaSponsorships?.data || [],
    }
  }

  return {
    props,
    revalidate: 60,
  }
}
