import { Hidden } from '@mui/material'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import HomeBuyVendorBadge from 'src/components/global/HomeBuyVendorBadge/HomeBuyVendorBadge'

import buyertech from '../../assets/buyertech.png'
import { IconButton } from '../../components/global/IconButton/IconButton'

interface VendorSectionProps {
  className?: string
}
interface VendorInfo {
  vendorhead: string
  vendorcontent: string
  vendorclaim: string
}
const VendorInfo: VendorInfo = {
  vendorhead: 'For Vendors',
  vendorcontent:
    'Legaltech Hub enables vendors to get exposure to the right buying audience, ensuring that your product is included in evaluations when a law firm or in-house department is in the market for a new tool. Create an account to add your listing to Legaltech Hub, claim, update, or upgrade your existing listings, and to communicate with your buying audience.',
  vendorclaim: 'Learn More',
}

/**
 * Primary UI component for user interaction
 */
export const VendorSection: React.FC<VendorSectionProps> = ({ className, ...props }) => {
  const data = VendorInfo
  return (
    <div className={cn(className, 'flex flex-row justify-center')} {...props}>
      <div className="px-[1.25rem] !max-w-[1100px] w-full flex flex-row items-center py-2">
        {/* web view*/}
        <Hidden mdDown>
          <div className=" grid py-4 gap-[5rem] sm:grid-cols-2 justify-between">
            <div className="">
              <div className="md:float-left">
                <Image className="" src={buyertech} alt="" />
              </div>
            </div>
            <div className="self-center float-right">
              <HomeBuyVendorBadge />
              <div className="pt-[0.5rem] pb-[0.625rem] text-[1.5rem] font-bold font-secondary text-[#222222]">
                {data.vendorhead}
              </div>
              <div className="text-[1.125rem] font-primary font-regular text-darkgray">{data.vendorcontent}</div>
              <Link href={'/requestvendor'}>
                <IconButton type="primary" label={data.vendorclaim} className="w-[9.625rem] h-[3.125rem] pt-5" />
              </Link>
            </div>
          </div>
        </Hidden>

        {/* mobile view */}
        <Hidden mdUp>
          <div className="flex flex-col justify-between">
            <div className="self-center">
              <HomeBuyVendorBadge />
              <div className="pt-[0.5rem] pb-[0.625rem] text-[1.5rem] font-bold font-secondary text-[#222222] ">
                {data.vendorhead}
              </div>
              <div className="text-[1.125rem] font-primary font-regular text-darkgray leading-[1.375rem]">
                {data.vendorcontent}
              </div>
              <Link href={'/requestvendor'}>
                <IconButton type="primary" label={data.vendorclaim} className="w-[9.625rem] h-[3.125rem] pt-5" />
              </Link>
            </div>
            <div>
              <div className="mt-[2.075rem]">
                <Image className="" src={buyertech} alt="" />
              </div>
            </div>
          </div>
        </Hidden>
      </div>
    </div>
  )
}
