import IonIcon from '@reacticons/ionicons'
import React from 'react'

import { PremiumContentCategory } from './PremiumContentCategory'

export const PremiumContentCategories: React.FC = () => {
  return (
    <div className="w-full large-lg:px-0 py-[2.5rem] bg-warmBlue items-center flex">
      <div className="grid items-center m-auto w-4/5 gap-[40px] lg:grid-cols-3 grid-cols-1">
        <PremiumContentCategory
          icon={<IonIcon name="compass-outline" className="text-[2.4rem] text-navybright" />}
          title="Solution Guides"
          description="Resources by our experts on each of our premium categories, landscapes, functional requirements, evaluation criteria and much more"
          url="/solution-guides/"
        />
        <PremiumContentCategory
          icon={<IonIcon name="information" className="text-[2.4rem] text-navybright" />}
          title="Know-How"
          description="Know how about digital transformation, knowledge management and innovation in law firms and 
          in-house legal departments"
          url="/know-how/"
        />
        <PremiumContentCategory
          icon={<IonIcon name="person-circle-outline" className="text-[2.4rem] text-navybright" />}
          title="Legaltech Resources for Lawyers"
          description="Resources to increase lawyer fluency on key legaltech topics such as AI, automation and data."
          url="/legaltech-resources-for-lawyers/"
        />
      </div>
    </div>
  )
}
