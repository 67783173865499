import cn from 'classnames'
import Link from 'next/link'
import { IconButton } from 'src/components/global/IconButton/IconButton'
import { ITopicItemFragment } from 'src/graphql/generated/hooks'

export interface TopicProps {
  className?: string
  topics: ITopicItemFragment[]
}

export const HomeTopics: React.FC<TopicProps> = ({ className, topics }) => {
  return (
    <div className={cn('grid md:grid-flow-col grid-rows-6 gap-2 mb-5', className)}>
      {topics.map((topic, idx) => {
        return (
          <Link key={idx} href={'/topics/' + topic.attributes?.slug}>
            <IconButton
              type="icontopic"
              label={topic.attributes?.name || ''}
              icon={topic.attributes?.iconName || ''}
              className="w-full"
            />
          </Link>
        )
      }) || []}
    </div>
  )
}
