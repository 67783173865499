import cn from 'classnames'
import { ReactNode } from 'react'

import styles from './HtmlContentViewer.module.scss'

export interface HtmlContentViewerProps {
  className?: string
  contentClassName?: string
  id?: string
  html: string | TrustedHTML
  image?: ReactNode | null
}

export const HtmlContentViewer: React.FC<HtmlContentViewerProps> = ({
  className = '',
  contentClassName = 'font-primary',
  id,
  html,
  image,
}) => {
  return image ? (
    <div className={cn(className)}>
      <div className="md:float-right md:w-[30vw] px-3 pb-3 md:inline-block">{image}</div>
      <div
        id={id}
        className={cn(contentClassName, styles.ckContent, 'html-show')}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  ) : (
    <div
      id={id}
      className={cn(className, styles.ckContent, contentClassName, 'html-show')}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}
