import React from 'react'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'
import { IIltaSponsorshipItemFragment } from 'src/graphql/generated/hooks'

interface Props {
  litaSponsorships: IIltaSponsorshipItemFragment[]
}

export const IltaSponsorshipLevels: React.FC<Props> = ({ litaSponsorships }) => {
  return (
    <div>
      <div className="grid md:grid-cols-3 gap-2 items-start">
        {litaSponsorships?.map((sponsorship, idx) => {
          return (
            <CompatibleLink
              className="flex flex-col cursor-pointer"
              key={idx}
              href={{ pathname: '/search', query: { sponsorLevels: sponsorship.id } }}
            >
              <div className="rounded-lg bg-clouds w-full md:w-[352px] h-[180px] flex flex-row justify-center m-auto items-center">
                <img className="w-40" src={sponsorship.attributes?.logo?.data?.attributes?.url} alt="" />
              </div>
              <span className="font-primary font-bold text-[18px] text-textdark mt-[20px]">
                {sponsorship.attributes?.name}
              </span>
              <span className="font-secondary text-[16px] text-darkgray mt-[4px]">
                {sponsorship.attributes?.description}
              </span>
            </CompatibleLink>
          )
        })}
      </div>
    </div>
  )
}
