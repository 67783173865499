import cn from 'classnames'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { HtmlContentViewer } from 'src/components/global/HtmlContentViewer/HtmlContentViewer'

export interface CollectionCardProps {
  title: string | null | undefined
  url: string
  description: string | null | undefined
}

export const CollectionCard: React.FC<PropsWithChildren<CollectionCardProps>> = ({ title, url, description }) => {
  return (
    <Link href={url} passHref className={cn('h-[15rem] w-full bg-white rounded-md px-8 py-6')}>
      <div className="flex flex-col h-[10rem] mb-[0.3rem]">
        <p className="text-textdark font-bold text-[1.4rem] mt-1 h-[3.1rem] overflow-hidden leading-6">{title}</p>
        <div className="h-[4.8rem] mt-[.7rem] overflow-hidden">
          <HtmlContentViewer
            html={description || ''}
            contentClassName="header-des-html text-[1rem] font-primary truncate"
          />
        </div>
      </div>
      <p className="font-primary font-bold text-[15px] leading-[18px] mr-[0.5rem] ml-[5px] text-textdark underline">
        {'Explore ' + title}
      </p>
    </Link>
  )
}
